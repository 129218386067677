import React, { useState, useEffect } from 'react';

const ModalContact = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
        setIsOpen(true);
      },30000)
  }, [])

  return (
    <div className={`fixed inset-0 z-10 overflow-y-auto ${isOpen ? 'block' : 'hidden'}`}>
      <div className="flex items-center justify-center min-h-screen p-6">
        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" onClick={closeModal}></div>

        <section className="fixed z-30 rounded-lg shadow-2xl w-full sm:w-4/5 xl:w-3/5 md:grid md:grid-cols-3 bg-white">
          <img
            alt="Trainer"
            src="https://gorynych63.ru/wp-content/uploads/2017/03/73369906.jpg"
            className="h-32 rounded-t-lg md:rounded-l-lg md:rounded-r-none w-full object-cover md:h-full"
          />

          <div className="p-4 text-center sm:p-6 md:col-span-2 lg:p-8">
            <p className="text-sm font-semibold text-black uppercase tracking-widest">Почему вы все еще ждете?</p>

            <h2 className="mt-6 font-black uppercase">
              <span className="text-2xl text-black sm:text-5xl lg:text-6xl">ПОЛУЧИТЕ СКИДКУ 20%</span>

              <span className="mt-2 block text-xs sm:text-sm">В будние дни с 9:00 до 16:00</span>
            </h2>

            <a
              className="mt-8 inline-block w-full bg-[#C8995D] hover:bg-[#c48e47] py-4 text-sm font-bold uppercase tracking-widest text-white"
              href="tel:80291227795"
            >
              ЗАБРОНИРОВАТЬ
            </a>

            <p className="mt-8 text-xs font-medium uppercase text-gray-400">
            бронирование заранее
            </p>

            <button
              className="absolute top-2 right-2 p-2 w-10 text-white bg-black rounded-full"
              onClick={closeModal}
            >
              ✖
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ModalContact;
