import React from 'react';

const AboutUs = () => {
    return (
    <section id='about-us' className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-teal-accent-400">
          </div>
          <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-[#C8995D] sm:text-4xl sm:leading-none">
              О нас
            </h2>
            <p className="text-base text-black md:text-lg">
            Мы - ваш уютный уголок в Техасе, где современный комфорт встречается с традиционными методами релаксации. Наша сауна - это не просто место, где вы можете отвлечься от повседневной суеты, но и настоящий центр восстановления для вашего тела и души.
            </p>
          </div>
          <div>
          </div>
        </div>
        <div className="flex items-center justify-center -mx-4 lg:pl-8">
          <div className="flex flex-col items-end px-3">
            <img
              className="object-cover mb-6 rounded shadow-lg h-40 w-48 sm:h-48 xl:h-56 sm:w-48 xl:w-80"
              src="img/bake.jpg"
              alt="Аутентичная печь в финской сауне в Бобруйск. ФОК «Техас», создающая уникальную атмосферу тепла и комфорта"
            />
            <img
              className="object-cover w-40 h-36 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-60"
              src="img/karaoke-zone.jpg"
              alt="Зажигательная атмосфера караоке в Сауне в Техасе. Бобруйск: место для веселья и незабываемых моментов"
            />
          </div>
          <div className="px-3">
            <img
              className="object-cover w-48 h-48 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
              src="img/sauna-broom.jpg"
              alt="Парные веники в Финской Сауне в Техасе, Бобруйск, ул. Сикорского, 11, ФОК «Техас»: традиционный аксессуар для полного погружения в сауновый ритуал"
            />
          </div>
        </div>
      </div>
    </section>
    );
};

export default AboutUs;
