import React from 'react';

const Prices = () => {
    return (
        <section id='price' className="text-gray-600 body-font">
  <div className="container flex flex-col lg:gap-10 sm:flex-row-reverse max-screen-sm:px-3 md:px-5 py-24 mx-auto">
    <div className="flex flex-col text-center w-full mb-20">
      <h2 className="sm:text-4xl text-3xl font-bold title-font text-left mb-4 text-[#C8995D]">Цены</h2>
      <p className="lg:w-4/3 mx-auto text-black leading-relaxed text-left text-base">В Сауне в Техасе мы предлагаем разнообразные варианты посещения для обеспечения наших гостей комфортным и приятным отдыхом.<br/><br/>

<li>До 6-ти человек, 1 час: Наслаждайтесь часом полного расслабления в компании друзей или близких всего за 40 BYN.</li>

<li>До 6-ти человек, Более 2-х часов: Если вы хотите продлить удовольствие, предлагаем вам более двух часов отдыха за 37 BYN.</li>

<li>Свыше 6-ти человек, 1 час: Групповое посещение в течение часа обойдется вам всего в 40 BYN.</li>

При посещении в группе свыше 6 человек дополнительные участники оплачивают всего лишь 5 BYN за каждого последующего человека.<br/><br/>

Выберите наиболее подходящий формат для вашего отдыха в Сауне в Техасе, где забота о вашем комфорте и удовлетворении нашей первостепенной задачей.</p>
    </div>
    <div className=" w-full flex-col mx-auto overflow-auto">
      <table className="table-auto w-full text-left whitespace-no-wrap">
        <thead>
          <tr>
            <th className="max-lg:py-2 max-lg:px-0.5 lg:px-4 lg:py-3 title-font tracking-wider font-medium text-gray-900 text-xs sm:text-sm bg-gray-100 rounded-tl rounded-bl">Виды посещений</th>
            <th className="max-lg:py-2 max-lg:px-0.5 lg:px-4 lg:py-3 title-font tracking-wider font-medium text-gray-900 text-xs sm:text-sm bg-gray-100">Время</th>
            <th className="max-lg:py-2 max-lg:px-0.5 lg:px-4 lg:py-3 title-font tracking-wider font-medium text-gray-900 text-xs sm:text-sm bg-gray-100">Цена</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="max-lg:py-2 max-lg:px-1 lg:px-4 lg:py-3 text-xs sm:text-sm">До 6-ти человек</td>
            <td className="max-lg:py-2 max-lg:px-1 lg:px-4 lg:py-3 text-xs sm:text-sm">1 час</td>
            <td className="max-lg:py-2 max-lg:px-1 lg:px-4 lg:py-3 text-xs sm:text-base font-semibold  text-gray-900">40 BYN</td>

          </tr>
          <tr>
            <td className="border-t-2 border-gray-200 text-xs sm:text-sm max-lg:py-2 max-lg:px-1 lg:px-4 lg:py-3">До 6-ти человек</td>
            <td className="border-t-2 border-gray-200 text-xs sm:text-sm max-lg:py-2 max-lg:px-1 lg:px-4 lg:py-3">Более 2-х часов</td>
            <td className="border-t-2 border-gray-200 max-lg:py-2 max-lg:px-1 lg:px-4 lg:py-3 text-xs sm:text-base font-semibold  text-gray-900">37 BYN</td>
          </tr>
          <tr>
            <td className="border-t-2 border-b-2 border-gray-200 max-lg:py-2 max-lg:px-1 lg:px-4 lg:py-3 text-xs sm:text-sm">Свыше 6-ти человек</td>
            <td className="border-t-2 border-b-2 border-gray-200 max-lg:py-2 max-lg:px-1 lg:px-4 lg:py-3 text-xs sm:text-sm">1 час</td>
            <td className="border-t-2 border-b-2 border-gray-200 max-lg:py-2 max-lg:px-1 lg:px-4 lg:py-3 text-xs sm:text-base font-semibold text-gray-900">40 BYN<span className="font-light text-xs"><br/>+5р за каждого<br/>последующего</span></td>
          </tr>
        </tbody>
      </table>
    <div className="flex sm:pl-4 mt-4 w-full mx-auto">
      <a href='/' className="text-[#C8995D] inline-flex hover:translate-x-1 transition-all text-xs sm:text-base items-center md:mb-2 lg:mb-0">Узнать больше
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </a>
      <a href='tel:+375(29)122-77-95' className="flex ml-auto text-white bg-[#C8995D] border-0 sm:py-2 sm:px-6 no-underline px-3 py-2 focus:outline-none hover:bg-[#be8a45] rounded">Забронировать</a>
        </div>
    </div>
  </div>
</section>
    );
};

export default Prices;
