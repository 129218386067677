import React from 'react';

export const Gallery = () => {
    return (
        <section id='gallery' className="container mx-auto px-2 py-2 mb-20 lg:px-32 lg:pt-24">
        <h2 className='text-center text-[#C8995D] mb-10 font-bold'>Галерея</h2>
          <div className="-m-1 flex flex-wrap md:-m-2">
            <div className="flex w-1/2 flex-wrap">
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="Зона караоке в Сауне в Техасе, ул. Сикорского, 11, ФОК «Техас». Насладитесь веселыми моментами и отличным настроением в нашей уютной караоке-зоне."
                  className="block h-full w-full rounded-lg object-cover object-center"
                  src="img/karaoke.jpg" />
              </div>
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="Зона отдыха с большим телевизором, диваном и колонкой в Сауне в Техасе, Бобруйск."
                  title="Зона отдыха в Сауне в Техасе. Бобруйск"
                  className="block h-full w-full rounded-lg object-cover object-center"
                  src="img/karaoke-zone.jpg" />
              </div>
              <div className="w-full p-1 md:p-2">
                <img
                  alt="Бобруйск, ул. Сикорского, 11, ФОК «Техас». «Сауна в Техасе» наслаждайтесь финской сауной, крепким паром и приятным ароматом древесины"
                  className="block h-full w-full rounded-lg object-cover object-center"
                  title="Финская Сауна в Техасе г.Бобруйск"
                  src="img/sauna.jpg" />
              </div>
            </div>
            <div className="flex w-1/2 flex-wrap">
              <div className="w-full p-1 md:p-2">
                <img
                  alt="Уютная зона отдыха в сауне в Техасе. Бобруйск"
                  className="block h-full w-full rounded-lg object-cover object-center"
                  src="img/chill-zone.jpg" />
              </div>
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="Печь в Финской Сауне в Техасе, в сердце города Бобруйск. Откройте для себя аутентичный опыт сауновых процедур в нашем уютном заведении в «Сауне в Техасе»."
                  className="block h-full w-full rounded-lg object-cover object-center"
                  src="img/bake.jpg" />
              </div>
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="Парные веники в Финской Сауне в Техасе, Бобруйск. Полное погружение в атмосферу традиционного саунового ритуала с теплом, веником и приятной ароматизированной парной."
                  className="block h-full w-full rounded-lg object-cover object-center"
                  src="img/sauna-broom.jpg" />
              </div>
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="Зона комфорта в Финской Сауне в Техасе, Бобруйск. Душевая кабина и зона отдыха"
                  className="block h-full w-full rounded-lg object-cover object-center"
                  src="img/bathroom.jpg" />
              </div>
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="Парные веники в Финской Сауне в Техасе, Бобруйск. Полное погружение в атмосферу традиционного саунового ритуала с теплом, веником и приятной ароматизированной парной."
                  className="block h-full w-full rounded-lg object-cover object-center"
                  src="img/akapel.jpg" />
              </div>
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="Зона отдыха в Техасе, Бобруйск. Отдохните от всего с музыкой, сауной и любовью."
                  className="block h-full w-full rounded-lg object-cover object-center"
                  src="img/chillzone-with-monitor.jpg" />
              </div>
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="Купль в Сауне в Техасе, в городе Бобруйск. Полное погружение в атмосферу традиционного саунового ритуала."
                  className="block h-full w-full rounded-lg object-cover object-center"
                  src="img/akapel2.png" />
              </div>
            </div>
          </div>
        </section>
    );
};
