import './App.css';
import AboutUs from './components/AboutUs/AboutUs';
import Header from './components/Header/Header';
import MainCarousel from './components/MainCarousel/MainCarousel';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ModalContact from './components/ModalContact/ModalContact';
import WeOnMap from './components/WeOnMap/WeOnMap';
import Prices from './components/Prices/Prices';
import Footer from './components/Footer/Footer';
import { Gallery } from './components/Gallery/Gallery';

function App() {
  return (
      <Router>
        <Header/>
        <Routes>
        <Route
            exact
            path="/"
            element={
            <>
              <MainCarousel/>
              <AboutUs/>
              <ModalContact/>
              <Prices/>
              <Gallery/>
              <WeOnMap/>
              <Footer/>
            </>
            }
        />
        </Routes>
      </Router>
  );
}

export default App;
