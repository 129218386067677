import React, { useState } from 'react';

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const onToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const onCloseMenu = () => {
    setToggleMenu(false);
  }

  return (
    <header className='bg-white z-10 flex items-center justify-between px-4 sm:px-10 w-full h-20 shadow fixed'>
      <div className="logo">
        <a href="/">
          <img src="img/logo.webp" className='w-20' alt="logo" />
        </a>
      </div>
      <nav className='hidden lg:flex'>
        <ul className='flex pl-40 m-0'>
          <li className='p-1.5'><a className='text-[#C8995D] no-underline font-medium' href="#about-us">О нас</a></li>
          <li className='p-1.5'><a className='text-[#C8995D] no-underline font-medium' href="#gallery">Галерея</a></li>
          <li className='p-1.5'><a className='text-[#C8995D] no-underline font-medium' href="#price">Цены</a></li>
          <li className='p-1.5'><a className='text-[#C8995D] no-underline font-medium' href="#we-on-map">Мы на карте</a></li>
        </ul>
      </nav>
        <div className="hidden lg:flex">
            <a className='px-1' href="/">
            <img className='w-6 transform transition-transform hover:scale-95' src="img/viber.png" alt="Viber Logo" />
            </a>
            <a className='px-1' href="/">
            <img className='w-6 transform transition-transform hover:scale-95' src="img/telegram.png" alt="Telegram Logo" />
            </a>
            <a className='px-1' href="https://www.instagram.com/sayna_bobruysk/">
            <img className='w-6 transform transition-transform hover:scale-95' src="img/instagram.png" alt="Instagram Logo" />
            </a>
            <a className='text-[#C8995D] pl-5' href="tel:80291227795">80(29)122-77-95</a>
        </div>
      <button onClick={onToggleMenu} className="block lg:hidden cursor-pointer hamburger-menu">
        <div className="w-6 bg-[#C8995D] rounded-md h-1"></div>
        <div className="w-6 bg-[#C8995D] my-1 rounded-md h-1"></div>
        <div className="w-6 bg-[#C8995D] rounded-md h-1"></div>
      </button>
      {toggleMenu && (
        <div className="fixed flex-col top-20 right-0 w-72 h-72 bg-gray-900 bg-opacity-50 flex items-start justify-center">
          <ul className="text-white text-left">
            <li className='p-1.5'><a className='text-[#C8995D] no-underline font-medium' href="#about-us">О нас</a></li>
            <li className='p-1.5'><a className='text-[#C8995D] no-underline font-medium' href="#gallery">Галерея</a></li>
            <li className='p-1.5'><a className='text-[#C8995D] no-underline font-medium' href="#price">Цены</a></li>
            <li className='p-1.5'><a className='text-[#C8995D] no-underline font-medium' href="#we-on-map">Мы на карте</a></li>
          </ul>
          <div className='flex pl-8'>
          <a className='px-1' href="/">
            <img className='w-6 transform transition-transform hover:scale-95' src="img/viber.png" alt="Viber" />
            </a>
            <a className='px-1' href="/">
            <img className='w-6 transform transition-transform hover:scale-95' src="img/telegram.png" alt="Telegram" />
            </a>
            <a className='px-1' href="https://www.instagram.com/sayna_bobruysk/">
            <img className='w-6 transform transition-transform hover:scale-95' src="img/instagram.png" alt="Telegram" />
            </a>
            <a className='text-[#C8995D] px-1' href="tel:80291227795">80(29)122-77-95</a>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
