import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './MainCarousel.css';

const MainCarousel = () => {
    return (
        <main>
            <Carousel pause={false} indicators={false} fade>
      <Carousel.Item>
      <div className="carouselimg1"></div>
        <Carousel.Caption className=''>
          <h1 className='text-3xl text-left sm:text-5xl font-semibold'>Приглашаем вас в Финскую Сауну</h1>
          <p className='text-left'>Финская Сауна в Техасе, городе Бобруйск! Здесь, в самом сердце торгового центра "Техас", мы предлагаем вам не просто сауну, а настоящий оазис уюта.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="carouselimg2"></div>
        <Carousel.Caption>
          <h1 className='text-3xl text-left sm:text-5xl font-semibold'>Отпускайте напряжение: Релакс и Веселье в Уютной Финской Сауне Техаса</h1>
          <p className='text-left'>Отдыхните от повседневной суеты и окунитесь в атмосферу тепла и уюта финской сауны. Наши профессиональные услуги гарантируют вам полный релакс, а караоке добавит веселья вашему вечеру.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="carouselimg3"></div>
        <Carousel.Caption>
          <h1 className='text-3xl text-left sm:text-5xl font-semibold'>Опробуйте наш уникальный подход к клиентам</h1>
          <p className='text-left'>
          Мы приглашаем вас настоящими мастерами финского отдыха. Представьте себе уютное пространство, наполненное теплом и благополучием. Наши профессионалы обеспечат вас вниманием, заботой и уникальным опытом релаксации.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
        </main>
    );
};

export default MainCarousel;
