import React from 'react';

const Footer = () => {
    return (
        

<footer className="bg-white rounded-lg dark:bg-gray-900 m-4">
    <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
            <a href="/" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                <img src="img/logo.webp" className="h-12" alt="Сауна Техас Лого" />
            </a>
            <ul className="flex flex-wrap pl-0 items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                <li>
                    <a href="#about-us" className=" text-[#C8995D] hover:underline me-4 md:me-6">О нас</a>
                </li>
                <li>
                    <a href="/" className="text-[#C8995D] hover:underline me-4 md:me-6">Политика конфиденциальности</a>
                </li>
            </ul>
            <div className='flex'>
                <img className='md:w-24 h-6 w-16 md:h-7 pr-4' src="img/visa-logo.png" alt="visa" />
                <img className='pl-4 w-14 md:w-16' src="img/mastercard-logo.png" alt="mastercard" />
            </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="/" className="text-gray-500 hover:underline">Сауна в Техасе</a>. All Rights Reserved.</span>
    </div>
</footer>
    );
};

export default Footer;
