import React from 'react';

const WeOnMap = () => {
    return (
        <section id='we-on-map' className='flex flex-col justify-center items-center'>
            <h2 className='text-[#C8995D] font-bold'>Мы на карте</h2>
            <p className='text-gray-600 text-xs text-center md:text-sm mb-4'>
            Вы найдете нас по адресу: ул. Сикорского 11 /1 - "Сауна в Техасе", ФОК «Техас», Бобруйск
            </p>
            <iframe title="Местоположение ФОК «Техас». 'Сауна в Техасе', ул. Сикорского 11 /1 , Бобруйск" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1195.011921576704!2d29.222339891475638!3d53.19948859522906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46d733110841e755%3A0x92853fa5c4c78744!2z0KTQntCaIMKr0KLQldCl0JDQocK7!5e0!3m2!1sru!2spl!4v1701978494925!5m2!1sru!2spl" width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </section>
    );
};

export default WeOnMap;
